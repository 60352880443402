import vegeelLogo from "assets/images/vegeel-logo.svg";
import Navlink from "components/navlink";
import { sidebarTop } from "constants/home";
import { useFrameworkContext, useSidebarContext } from "context";
import { useAppContext } from "context/home/app";

export default function Sidebar() {
  const { getOrganizationInfo } = useAppContext();
  const orgInfo = getOrganizationInfo();
  const { sidebarData } = useSidebarContext();
  console.log(sidebarData, "side bar");

  return (
    <div className="h-full w-[17%] min-w-[240px] py-[12px] bg-[#060B59] overflow-y-scroll">
      <div className="px-[22px] py-[16px] flex items-center">
        <img src={vegeelLogo} alt="vegeel logo" />
      </div>
      <div className="bg-[#041D73] py-[12px] px-[20px] flex gap-[10px]">
        <img
          src={
            orgInfo?.logo instanceof File
              ? URL.createObjectURL(orgInfo.logo)
              : orgInfo?.logo || undefined
          }
          alt=""
          className="w-[28px] h-[28px]"
        />

        <h1 className="text-[14px] font-medium leading-[24px]  text-white">
          {orgInfo?.displayName}
        </h1>
      </div>
      <div className="px-[22px] h-fit mx-auto flex flex-col gap-1 my-2">
        {sidebarTop.map((link, i) => {
          return (
            <Navlink
              icon={link.icon}
              link={link.link}
              title={link.title}
              key={i}
            />
          );
        })}
      </div>
      <div className="px-[22px] mx-auto flex flex-col gap-2 w-full">
        {sidebarData.map((item, i) => {
          return (
            <div key={i} className="flex flex-col gap-1 w-full">
              <h1 className="text-white font-bold text-[12px] leading-[20px] uppercase">
                {item.parentTitle.title}
              </h1>
              <div className="flex flex-col gap-1 w-full">
                {item.parentTitle.paths.map((path, i) => {
                  return (
                    <Navlink
                      icon={path.icon}
                      link={path.link}
                      title={path.title}
                      key={i}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
