import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const getAllEvidenceService = async () => {
  const url = `/evidencehub`;
  const method = "get";

  return apiService(url, method, null, true);
};
const getEvidenceStats = async (publicId: string) => {
  const url = `/evidencehub/dashboard?vegeelFrameworkPublicId=${publicId}`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const downloadAllEvidence = async () => {
  const url = `/evidencehub/all-zipped`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const createEvidenceService = async (body: any) => {
  const url = `/evidencehub`;
  const method = "post";
  return apiService(url, method, body, true);
};

const getEvidenceByNameService = async (name: string) => {
  const url = `/evidencehub?documentName=${name}`;
  const method = "get";
  return apiService(url, method, null, true);
};

const getEvidenceByIdService = async (
  publicId: string,
  page: number,
  size: number,
  params?: {
    owner?: any;
    approver?: any;
    status?: any;
    name?: any;
  }
) => {
  const owner = params?.owner;
  const approver = params?.approver;
  const status = params?.status;
  const name = params?.name;
  let url = `/evidencehub?vegeelFrameworkPublicId=${publicId}&page=${page}&size=${size}&sort=createdDate%2Cdesc`;

  if (owner) {
    url += `&owner=${owner}`;
  }
  if (approver) {
    url += `&approver=${approver}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (name) {
    url += `&documentName=${name}`;
  }

  const method = "get";
  return apiService(url, method, null, true, {}, {}, baseurl);
};

const deleteEvidenceService = async (publicId: any) => {
  const url = `/evidencehub/${publicId}`;
  const method = "delete";
  return apiService(url, method, null, true, {}, {}, baseurl);
};

const updateEvidenceService = async (publicId: string, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/evidencehub/${publicId}`;
  const method = "put";
  return apiService(url, method, body, true, headers, {}, baseurl);
};

const getEvidenceByFrameworkIdQueryService = async (frameworkId: any) => {
  const url = `/evidencehub/getbyFrameworkPublicId?vegeelFrameworkPublicId=${frameworkId}`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const updateDocumentStatusService = async (
  evidenceHubPublicId: any,
  action: any
) => {
  const url = `/evidencehub/${evidenceHubPublicId}/${action}`;
  const method = "put";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getEvidenceByFrameworkIdService = async (frameworkPublicId: any) => {
  const url = `/evidencehub/framework/${frameworkPublicId}`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getEvidenceByFrameworkIdAndDocumentNameService = async (
  frameworkId: any,
  documentName: any
) => {
  const url = `evidencehub/getbyFrameworkAndDocumentName?vegeelFrameworkPublicId=$${frameworkId}&documentName=${documentName}`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getEvidenceDashboardService = async (frameworkId: any) => {
  const url = `/evidencehub/dashboard?vegeelFrameworkPublicId=${frameworkId}`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getEvidenceByFrameworkId = async (
  frameworkId: string,
  status?: string
) => {
  const url = `/evidencehub?vegeelFrameworkPublicId=${frameworkId}`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getDeletedEvidenceService = async () => {
  const url = `/evidencehub/dashboard`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getCommentOnEvidenceService = async (documentId: any) => {
  const url = `/evidencehub/${documentId}/comments`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};
const getCommentReplyOnEvidenceService = async (
  documentId: any,
  commentId: any
) => {
  const url = `/evidencehub/${documentId}/${commentId}/replies`;
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const createCommentOnEvidenceService = async (documentId: any, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/evidencehub/${documentId}/create-comment`;
  const method = "post";
  return apiService(url, method, body, true, headers, {}, baseurl);
};
const replyCommentOnEvidenceService = async (
  documentId: any,
  commentId: any,
  body: any
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/evidencehub/${documentId}/${commentId}/reply-to-comment`;
  const method = "post";
  return apiService(url, method, body, true, headers, {}, baseurl);
};

export {
  getAllEvidenceService,
  createEvidenceService,
  getEvidenceByIdService,
  deleteEvidenceService,
  updateEvidenceService,
  getEvidenceByFrameworkIdService,
  getEvidenceByFrameworkIdAndDocumentNameService,
  getEvidenceByFrameworkIdQueryService,
  getEvidenceDashboardService,
  getEvidenceByFrameworkId,
  getDeletedEvidenceService,
  updateDocumentStatusService,
  createCommentOnEvidenceService,
  getCommentOnEvidenceService,
  getEvidenceStats,
  getCommentReplyOnEvidenceService,
  replyCommentOnEvidenceService,
  getEvidenceByNameService,
  downloadAllEvidence,
};
