import { ReactComponent as left } from "assets/icons/arrow-left.svg";
import { ReactComponent as edit } from "assets/icons/edit-03.svg";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle-contained.svg";
import CustomIcon from "components/custom-icon";
import CommentTab from "components/framework/modal/tabs/comment";
import HistoryTab from "components/framework/modal/tabs/history";
import Loader from "components/loader";
import PDFViewer from "components/previewPDF";
import { useAppContext, useFrameworkContext } from "context";
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from "docx";
import { ContentState, convertToRaw } from "draft-js";
import QuillToolbar, { formats, modules } from "helpers/quill/QuillToolBar";
import useFetch from "hooks/useFetch";
import htmlToDraft from "html-to-draftjs";
import mammoth from "mammoth";
import React, { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { uploadFilesService } from "services";
import {
  getEvidenceByNameService,
  updateEvidenceService,
} from "services/evidenceHub";
import { toast } from "sonner";
import { IEvidenceHubItem } from "types/evidence-hub";
import {
  getFileExtension,
  replaceUnderscoresWithSpaces,
  timeAgo,
} from "utils/general";
import DocumentTypeTab from "./tabs/document-type";

export default function PreviewEvidenceHubEditor({ framworkname }: any) {
  const navigate = useNavigate();
  const [data, setData] = useState<any | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tempDocumentName, setTempDocumentName] = useState("");
  const [fileExtention, setFileExtention] = useState("");
  const [value, setValue] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [personnels, setPersonnels] = useState<any[]>([]);
  const [evidence, setEvidence] = useState<IEvidenceHubItem | null>(null);
  const [tab, setTab] = useState<number>(1);
  const { getOrganizationInfo, personnelInfoState } = useAppContext();
  const { activeFrameworks } = useFrameworkContext();
  const orgInfo = getOrganizationInfo();
  const [uploadResponse, setUploadResponse] = useState<any>(null);
  const [uploadLoading, setUploadLoading] = useState<any>(false);
  const [editEvidenceLoading, setEditEvidenceLoading] = useState<any>(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const {
    data: getAllEvidenceData,
    error: getAllEvidenceError,
    loading: getAllEvidenceLoading,
    fetchData: getAllEvidenceFetchData,
  } = useFetch<any>();

  const fectchEvidenceByName = useCallback(() => {
    if (framworkname) {
      getAllEvidenceFetchData(() => getEvidenceByNameService(framworkname));
    }
  }, [framworkname, getAllEvidenceFetchData]);

  useEffect(() => {
    if (framworkname) {
      fectchEvidenceByName();
    }
  }, [fectchEvidenceByName, framworkname]);

  useEffect(() => {
    if (getAllEvidenceData) {
      setEvidence(getAllEvidenceData.data.content[0]);
    }
  }, [getAllEvidenceData]);

  useEffect(() => {
    if (personnelInfoState) {
      const personnelOptions = personnelInfoState.content.map((user) => ({
        value: user.publicId,
        label: `${user.firstName} ${user.lastName}`,
        id: user.publicId,
        email: user.email,
      }));
      setPersonnels(personnelOptions);
    }
  }, [personnelInfoState]);

  useEffect(() => {
    if (evidence) {
      setUploadedFile(evidence.documentUrl);
    }
  }, [evidence]);

  useEffect(() => {
    if (evidence && evidence?.documentUrl) {
      const getDocument = async () => {
        try {
          const docName = evidence.documentUrl;
          const fileExtension = getFileExtension(docName);
          setFileExtention(fileExtension);
          if (fileExtension === "docx") {
            const response = await fetch(
              evidence.documentUrl.replace("http://", "https://")
            );
            const arrayBuffer = await response.arrayBuffer();
            const result = await mammoth.convertToHtml({
              arrayBuffer: arrayBuffer,
            });
            const html = result.value;

            const ownerName = evidence.owner
              ? `${evidence.owner.firstName} ${evidence.owner.lastName}`
              : "N/A";
            const approverName = evidence.approver
              ? `${evidence.approver.firstName} ${evidence.approver.lastName}`
              : "N/A";

            const frameworkNames = evidence.vegeelFrameworkPublicIds
              .map((frameworkId: string) => {
                const framework = activeFrameworks?.find(
                  (fw) => fw.publicId === frameworkId
                );
                return framework ? framework.name : "Unknown Framework";
              })
              .join(", ");

            const metadata = `
            <br><br>---<br>
            <p><strong>Created By:</strong> ${evidence.createdBy || "N/A"}</p>
            <p><strong>Created Date:</strong> ${
              new Date(evidence.createdDate).toLocaleString() || "N/A"
            }</p>
            <p><strong>Last Modified By:</strong> ${
              evidence.lastModifiedBy || "N/A"
            }</p>
            <p><strong>Last Modified Date:</strong> ${
              new Date(evidence.lastModifiedDate).toLocaleString() || "N/A"
            }</p>
            <p><strong>Version:</strong> ${evidence.version || "N/A"}</p>
            <p><strong>Classification:</strong> ${
              replaceUnderscoresWithSpaces(evidence.classification) || "N/A"
            }</p>
            <p><strong>Expiration Date:</strong> ${
              new Date(evidence.expirationDate).toLocaleString() || "N/A"
            }</p>
            <p><strong>Review Cycle:</strong> ${
              evidence.reviewCycle || "N/A"
            }</p>
            <p><strong>Status:</strong> ${
              replaceUnderscoresWithSpaces(evidence.status) || "N/A"
            }</p>
            <p><strong>Owner:</strong> ${ownerName}</p>
            <p><strong>Approver:</strong> ${approverName}</p>
            <p><strong>Frameworks:</strong> ${frameworkNames}</p>
          `;

            const footnoteRegex = /<br><br>---<br>(.|\n)*$/;

            let updatedContent;
            if (html.match(footnoteRegex)) {
              updatedContent = html.replace(footnoteRegex, metadata);
            } else {
              updatedContent = `${html}${metadata}`;
            }

            setValue(updatedContent);
          } else return;
        } catch (error) {
          console.error("Error converting document to HTML:", error);
        }
      };
      getDocument();
    }
  }, [activeFrameworks, evidence]);

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleInputBlur = () => {
    if (data) {
      setData({ ...data, documentName: tempDocumentName });
    }
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempDocumentName(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const convertDraftToDocx = (content: any) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: content.blocks.map((block: any) => {
            switch (block.type) {
              case "header-one":
                return new Paragraph({
                  heading: HeadingLevel.HEADING_1,
                  children: [new TextRun(block.text)],
                });
              case "header-two":
                return new Paragraph({
                  heading: HeadingLevel.HEADING_2,
                  children: [new TextRun(block.text)],
                });
              case "paragraph":
              default:
                return new Paragraph({
                  children: [new TextRun(block.text)],
                });
            }
          }),
        },
      ],
    });
    return doc;
  };

  const handleUploadDocument = async () => {
    if (value && orgInfo) {
      console.log("second");
      try {
        const contentBlock = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap
        );
        const rawContent = convertToRaw(contentState);
        const doc = convertDraftToDocx(rawContent);
        const blob = await Packer.toBlob(doc);
        let filename = tempDocumentName || evidence?.documentName || "Untitled";
        if (!filename.toLowerCase().endsWith(".docx")) {
          filename += ".docx";
        }
        const file = new File([blob], filename, {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        setUploadLoading(true);
        const uploadResponse = await uploadFilesService(
          file,
          orgInfo.companyName
        );
        if (uploadResponse) {
          setUploadResponse(uploadResponse);
          setUploadedFile(uploadResponse.data.secure_url);
          setUploadLoading(false);
        } else {
          setUploadLoading(false);
        }
      } catch (error) {
        setUploadLoading(false);
      }
    }
  };

  const handleUpdateEvidence = async (data: any) => {
    if (uploadResponse && evidence) {
      const updateResponse = await updateEvidenceService(
        evidence?.publicId,
        data
      );
      if (updateResponse.isSuccess) {
        toast.success("Successfully updated evidence");
        navigate(-1);
        setEditEvidenceLoading(false);
      } else {
        toast.error("Error updating evidence");
        setEditEvidenceLoading(false);
      }
    }
  };

  const handleEditDocument = async () => {
    await handleUploadDocument();
  };

  const date = evidence?.createdDate ? new Date(evidence.createdDate) : null;

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = date
    ? date.toLocaleDateString("en-US", dateOptions)
    : "Date not available";

  let color;
  switch (evidence?.status) {
    case "APPROVED":
      color = "text-[#027A48] bg-[#ECFDF3]";
      break;
    case "IN REVIEW":
      color = "text-[#EA6F03] bg-[#FFFAEB]";
      break;
    default:
      color = "text-[#E84134] bg-[#EAECF0]";
  }

  if (getAllEvidenceLoading || editEvidenceLoading) {
    return <Loader />;
  }

  return (
    <div className="py-4">
      <div className="h-[36px] flex justify-between">
        <div className="bg-[#F2F5FF] px-[12px] py-[6px] rounded-md flex justify-center gap-[10px] items-center">
          <span className="text-[#1B4DFF] text-sm leading-[24px] font-medium">
            Edit document
          </span>
          <CustomIcon SvgIcon={HelpCircle} size={18} />
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => navigate(-1)}
            className="font-medium text-sm text-[#455468] border border-[#D7DFE9] bg-white flex gap-1 py-2 px-4 rounded-md"
          >
            <CustomIcon SvgIcon={left} size={18} />
            Back to evidence hub
          </button>

          <button
            onClick={handleEditDocument}
            className="font-medium text-sm flex gap-1 py-2 px-4 rounded-md bg-[#1B4DFF] text-[#FFFFFF]"
          >
            {uploadLoading ? "Saving changes" : "Save changes"}
          </button>
        </div>
      </div>
      <div className="w-full h-[calc(100%-34px)]">
        <div className="w-full h-fit">
          <div className="bg-white rounded-md w-fit py-2 px-4 text-lg font-bold text-[#2D3643] my-4 border border-[#EAECF0] flex gap-[50px] capitalize">
            {isEditing ? (
              <input
                type="text"
                value={tempDocumentName || framworkname}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleInputKeyDown}
                className="outline-none border-b-2 border-blue-500"
                autoFocus
              />
            ) : (
              <h1>{tempDocumentName || framworkname}</h1>
            )}
            <span onClick={handleEditClick} className="cursor-pointer">
              <CustomIcon SvgIcon={edit} size={24} />
            </span>
          </div>
          <div className="w-full flex items-center text-sm font-medium gap-3">
            <p className="text-[#455468]">Status</p>
            <div className="flex gap-1 items-center py-1 px-2 bg-[#FFFAEB] rounded-md">
              <div>
                <div
                  className="w-2 aspect-square rounded-full"
                  style={{
                    backgroundColor:
                      evidence?.status === "APPROVED"
                        ? "#027A48"
                        : evidence?.status === "IN REVIEW"
                        ? "#EA6F03"
                        : "#E84134",
                  }}
                ></div>
              </div>
              <p className={`${color}`}>
                {" "}
                {evidence?.status === "REQUEST APPROVAL"
                  ? "Pending Approval"
                  : evidence?.status || "N/A"}
              </p>
            </div>
            <p className="text-[#455468] border-l-2 border-[#455468] pl-3">
              Created on <span className="text-[#111111]">{formattedDate}</span>
            </p>

            <p className="text-[#455468] border-l-2 border-[#455468] pl-3">
              Last Updated{" "}
              <span className="text-[#111111]">
                {timeAgo(evidence?.lastModifiedDate ?? "")}
              </span>
            </p>
          </div>
        </div>

        <div className="w-full h-[calc(100%-130px)]">
          <div className="w-full h-full">
            <div className="w-full flex justify-between h-full">
              {fileExtention === "docx" && (
                <div className="w-[62%] h-full">
                  <QuillToolbar toolbarId={"t1"} />
                  <ReactQuill
                    className="border border-[#D7DFE9] bg-white !h-[500px]"
                    theme="snow"
                    value={value}
                    readOnly={false}
                    onChange={handleChange}
                    modules={modules("t1")}
                    formats={formats}
                  />
                </div>
              )}
              {fileExtention === "pdf" && evidence && (
                <div className="w-[62%] h-[500px]">
                  <PDFViewer
                    url={evidence.documentUrl}
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              )}

              <div className="flex flex-col gap-y-5 w-[35%] bg-white border border-[#D7DFE9] p-4 rounded-md">
                <div className="h-[40px] w-full flex items-center gap-2">
                  <div
                    className={`${
                      tab === 1
                        ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
                        : "text-[#8897AE] cursor-pointer text-sm"
                    } p-1`}
                    onClick={() => setTab(1)}
                  >
                    Document Properties
                  </div>
                  <div
                    className={`${
                      tab === 2
                        ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
                        : "text-[#8897AE] cursor-pointer text-sm"
                    } p-1`}
                    onClick={() => setTab(2)}
                  >
                    Comments
                  </div>
                  <div
                    className={`${
                      tab === 3
                        ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
                        : "text-[#8897AE] cursor-pointer text-sm"
                    } p-1`}
                    onClick={() => setTab(3)}
                  >
                    History
                  </div>
                </div>

                {tab === 1 && evidence && (
                  <DocumentTypeTab
                    evidence={evidence}
                    personnel={personnels}
                    tempDocumentName={tempDocumentName}
                    getAllEvidenceFetchData={fectchEvidenceByName}
                    uploadedFile={uploadedFile}
                    uploadResponse={uploadResponse}
                    handleUpdateEvidence={handleUpdateEvidence}
                    uploadLoading={uploadLoading}
                  />
                )}
                {tab === 2 && evidence && (
                  <CommentTab reqItems={evidence} path="evidencehub" />
                )}
                {tab === 3 && evidence && <HistoryTab reqItem={evidence} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
