import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, CheckboxProps, Spin } from "antd";
import { ReactComponent as left } from "assets/icons/arrow-left.svg";
import { ReactComponent as edit } from "assets/icons/edit-03.svg";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle-contained.svg";
import CustomIcon from "components/custom-icon";
import Loader from "components/loader";
import { getFileExtension } from "constants/customized-template/iso27001";
import { useAppContext, useFrameworkContext, useUploadContext } from "context";
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from "docx";
import { ContentState, convertToRaw } from "draft-js";
import QuillToolbar, { formats, modules } from "helpers/quill/QuillToolBar";
import SelectComponent from "helpers/select-input";
import useFetch from "hooks/useFetch";
import htmlToDraft from "html-to-draftjs";
import mammoth from "mammoth";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import { getFrameworksByIds, uploadFilesService } from "services";
import { createEvidenceService } from "services/evidenceHub";
import { getPolicyByDocumentName } from "services/policy-center";
import { toast } from "sonner";
import { Policy } from "types";
import { IPersonnel } from "types/personnel";
import * as XLSX from "xlsx";
import { GlobalWorkerOptions, getDocument as getDocs } from "pdfjs-dist";

interface FetchResponse {
  data: {
    content: Policy[];
    page: {
      size: number;
      number: number;
      totalElements: number;
      totalPages: number;
    };
  };
}

interface OptionType {
  value: string;
  label: string;
}
interface PersonnelOptionType {
  value: string;
  label: string;
  personnel: IPersonnel;
}

const options: OptionType[] = [
  { value: "PUBLIC", label: "Public" },
  { value: "INTERNAL_USE", label: "Internal use" },
  { value: "CONFIDENTIAL", label: "Confidential" },
  { value: "PRIVATE", label: "Private" },
];

const review: OptionType[] = [
  { value: "YEARLY", label: "Yearly" },
  { value: "QUARTERLY", label: "Quarterly" },
  { value: "MONTHLY", label: "Monthly" },
];

export default function PreviewPolicyHubEditor({ name }: any) {
  const navigate = useNavigate();
  const [data, setData] = useState<any | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tempDocumentName, setTempDocumentName] = useState(name);
  const [value, setValue] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const {
    documentName,
    classification,
    reviewCycle,
    owner,
    version,
    approver,
    vegeelFrameworkPublicIds,
    setDocumentName,
    setClassification,
    setReviewCycle,
    setOwner,
    setVersion,
    setApprover,
    setVegeelFrameworkPublicIds,
  } = useUploadContext();

  const [personnel, setPersonnel] = useState<PersonnelOptionType[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const { getOrganizationInfo, personnelInfoState } = useAppContext();
  const { activeFrameworks } = useFrameworkContext();

  const orgInfo = getOrganizationInfo();

  const params = useParams();

  useEffect(() => {
    if (personnelInfoState) {
      const personnelDropdown = personnelInfoState.content.map((personnel) => {
        return {
          value: personnel.publicId,
          label: personnel.firstName + " " + personnel.lastName,
          personnel,
        };
      });
      setPersonnel(personnelDropdown);
    }
  }, [personnelInfoState]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    const { value, checked } = e.target;
    setVegeelFrameworkPublicIds((prev: any) =>
      checked ? [...prev, value] : prev.filter((item: any) => item !== value)
    );
  };

  const {
    data: uploadDocsData,
    error: uploadDocsError,
    loading: uploadDocsLoading,
    fetchData: uploadDocsFetchData,
  } = useFetch<any>();

  const {
    data: documentData,
    error,
    fetchData,
    loading,
  } = useFetch<FetchResponse>();

  const {
    data: getFrameworkData,
    error: getFrameworkError,
    loading: getFrameworkLoading,
    fetchData: getFrameworkFetchData,
  } = useFetch<any>();

  const {
    data: dataSubmit,
    error: errorSubmit,
    loading: loadingSubmit,
    fetchData: fetchSubmit,
  } = useFetch();

  useEffect(() => {
    if (orgInfo) {
      getFrameworkFetchData(() =>
        getFrameworksByIds(
          orgInfo.businessFrameworks.map((framework) => framework.publicId)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (name) {
      fetchData(() => getPolicyByDocumentName(name));
    }
  }, [fetchData, name]);

  useEffect(() => {
    if (documentData && documentData.data.content[0].documentUrl) {
      const getDocument = async () => {
        try {
          const response = await fetch(
            documentData.data.content[0].documentUrl.replace(
              "http://",
              "https://"
            )
          );
          const arrayBuffer = await response.arrayBuffer();
          const result = await mammoth.convertToHtml({
            arrayBuffer: arrayBuffer,
          });
          const html = result.value;

          setValue(html);
        } catch (error) {
          console.error("Error converting document to HTML:", error);
        }
      };
      getDocument();
    }
  }, [documentData]);

  const handleChange = (value: string) => {
    setValue(value);
  };

  const onEditClick = () => {
    setIsEditable(true);
  };

  const handleInputBlur = () => {
    if (data) {
      setData({ ...data, documentName: tempDocumentName });
    }
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempDocumentName(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const convertDraftToDocx = (content: any) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: content.blocks.map((block: any) => {
            switch (block.type) {
              case "header-one":
                return new Paragraph({
                  heading: HeadingLevel.HEADING_1,
                  children: [new TextRun(block.text)],
                });
              case "header-two":
                return new Paragraph({
                  heading: HeadingLevel.HEADING_2,
                  children: [new TextRun(block.text)],
                });
              case "paragraph":
              default:
                return new Paragraph({
                  children: [new TextRun(block.text)],
                });
            }
          }),
        },
      ],
    });
    return doc;
  };

  const handleUploadDocument = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (value) {
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      );
      const rawContent = convertToRaw(contentState);
      const doc = convertDraftToDocx(rawContent);

      const blob = await Packer.toBlob(doc);
      const file = new File([blob], tempDocumentName, {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      uploadDocsFetchData(() =>
        uploadFilesService(file, "policycenterdocument")
      );
    }
  };

  useEffect(() => {
    if (uploadDocsData && uploadDocsData.data.url) {
      const payload = {
        documentName: tempDocumentName,
        documentUrl: uploadDocsData.data.url,
        classification: classification.value,
        version: version,
        reviewCycle: reviewCycle.value,
        ownerPublicId: owner,
        approverPublicId: approver,
        vegeelFrameworkPublicIds: vegeelFrameworkPublicIds,
      };

      fetchSubmit(() => createEvidenceService(payload));
    }
  }, [
    approver,
    classification,
    fetchSubmit,
    owner,
    reviewCycle,
    tempDocumentName,
    uploadDocsData,
    vegeelFrameworkPublicIds,
    version,
  ]);

  useEffect(() => {
    if (dataSubmit) {
      navigate(`/evidence-hub`);
    }
  }, [dataSubmit, errorSubmit, navigate]);

  useEffect(() => {
    if (getFrameworkError || error || errorSubmit || uploadDocsError) {
      toast.error(getFrameworkError || error || errorSubmit || uploadDocsError);
    }
  }, [errorSubmit, getFrameworkError, error, uploadDocsError]);

  if (loading || getFrameworkLoading || loadingSubmit) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="h-[36px] flex justify-between">
        <div className="bg-[#F2F5FF] px-[12px] py-[6px] rounded-md flex justify-center gap-[10px] items-center">
          <span className="text-[#1B4DFF] text-sm leading-[24px] font-medium">
            Create document
          </span>
          <CustomIcon SvgIcon={HelpCircle} size={18} />
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => navigate(-1)}
            className="font-medium text-sm text-[#455468] border border-[#D7DFE9] bg-white flex gap-1 py-2 px-4 rounded-md"
          >
            <CustomIcon SvgIcon={left} size={18} />
            Back
          </button>
        </div>
      </div>
      <div className="w-full h-[calc(100%-34px)]">
        <div className="w-full h-fit ">
          <div className="bg-white rounded-md w-fit py-2 px-4 text-lg font-bold text-[#2D3643] my-4 border border-[#EAECF0] flex gap-[50px]">
            {isEditing ? (
              <input
                type="text"
                value={tempDocumentName}
                onChange={(e) => handleInputChange(e)}
                onBlur={handleInputBlur}
                onKeyDown={handleInputKeyDown}
                className="outline-none border-b-2 border-blue-500"
                autoFocus
              />
            ) : (
              <h1>{tempDocumentName}</h1>
            )}
            <span onClick={handleEditClick} className="cursor-pointer">
              <CustomIcon SvgIcon={edit} size={24} />
            </span>
          </div>
        </div>

        <div className="w-full mt-4 h-[calc(100%-130px)]">
          <form className="w-full h-full">
            <div className="w-full flex justify-between h-full">
              <div className="w-[62%] h-full">
                <QuillToolbar toolbarId={"t1"} />
                <ReactQuill
                  className="border border-[#D7DFE9] bg-white !h-[500px]"
                  theme="snow"
                  value={value}
                  readOnly={false}
                  onChange={handleChange}
                  modules={modules("t1")}
                  formats={formats}
                />
              </div>

              <div className="flex flex-col gap-y-5 w-[35%]">
                <div className="bg-[#F2F5FF] px-[12px] py-[6px] rounded-md flex justify-center gap-[10px] items-center w-fit">
                  <span className="text-[#1B4DFF] text-sm leading-[24px] font-medium">
                    Document Properties
                  </span>
                  <CustomIcon SvgIcon={HelpCircle} size={18} />
                </div>

                <div>
                  <label htmlFor="" className="font-medium text-sm">
                    Classification<sup className="text-[#1B4DFF]">*</sup>
                  </label>
                  <div className="mt-1">
                    <SelectComponent
                      placeholder="eg. Public, Internal use, Confidential, Private"
                      options={options}
                      value={classification}
                      onChange={(value) => setClassification(value)}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Version<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <input
                      className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-2.5 placeholder:text-[#AFBACA]"
                      type="text"
                      placeholder="Version name"
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                    />
                  </div>

                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Review cycle<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <div className="mt-1">
                      <SelectComponent
                        placeholder="eg. Yearly, Quarterly"
                        options={review}
                        value={reviewCycle}
                        onChange={(value) => setReviewCycle(value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Owner<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <div className="mt-1">
                      <SelectComponent
                        placeholder="Select team member"
                        options={personnel.filter((person) => {
                          return (
                            person.personnel.roleName === "REVIEWER" ||
                            person.personnel.roleName === "SUPER_ADMIN"
                          );
                        })}
                        defaultValue={value}
                        // value={owner}
                        onChange={(value) => {
                          setOwner(value.personnel.publicId);
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <label htmlFor="" className="font-medium text-sm">
                      Approval<sup className="text-[#1B4DFF]">*</sup>
                    </label>
                    <div className="mt-1">
                      <SelectComponent
                        placeholder="Select team member"
                        options={personnel.filter((person) => {
                          return (
                            person.personnel.roleName === "APPROVER" ||
                            person.personnel.roleName === "SUPER_ADMIN"
                          );
                        })}
                        // value={approver}
                        defaultValue={approver}
                        onChange={(value) => {
                          setApprover(value.personnel.publicId);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="font-medium text-sm">
                    Tag to frameworks<sup className="text-[#1B4DFF]">*</sup>
                  </label>
                  <div className="grid grid-cols-2 gap-y-4 py-4 border-b border-[#D7DFE9]">
                    {activeFrameworks?.map((framework) => (
                      <div
                        className="flex items-center gap-4"
                        key={framework.publicId}
                      >
                        <Checkbox
                          value={framework.publicId}
                          onChange={onChange}
                          checked={vegeelFrameworkPublicIds.includes(
                            framework.publicId
                          )}
                        />
                        <div className="flex items-center gap-2">
                          <img
                            src={framework.logo}
                            alt={framework.name}
                            className="h-[24px] w-[24px]"
                          />
                          <p className="text-[#455468] font-medium text-sm">
                            {framework.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  onClick={handleUploadDocument}
                  className="font-medium text-sm text-[#fff] border border-[#D7DFE9] bg-[#0F3CD9] flex gap-1 py-2 px-4 rounded-md w-full justify-center items-center"
                >
                  {uploadDocsLoading ? (
                    <span className="flex gap-2 items-center">
                      Submitting{" "}
                      <Spin
                        indicator={<LoadingOutlined color="white" spin />}
                      />
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
