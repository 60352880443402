import overlay from "assets/images/anthenticated_overlay.svg";
import vegeelLogo from "assets/images/vegeel-logo.svg";
import Loader from "components/loader";
import { lazy, Suspense, useEffect, useState } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

// Layouts imports
import EditorContainer from "components/editor";
import NotFound from "components/NotFound";
import { FilteredDataProvider } from "context/home/filtered-data";
import Editor from "layouts/editor";
import { SettingsProvider } from "context/settings";
import Onboarding from "layouts/onboarding";
import Authenticated from "../layouts/authenticated";
import Unauthenticated from "../layouts/unauthenticated";
import { AuthProvider } from "context/auth";
import ErrorBoundary from "components/error-page";
import ProtectedRoute from "components/protected-route";

const CreateAudit = lazy(() => import("modules/home/audit/create"));

// Modules imports
const Signin = lazy(() => import("modules/authentication/sign-in"));
const ImplemeterAuth = lazy(() => import("modules/authentication/implementer"));
const ForgottenPassword = lazy(
  () => import("modules/authentication/forgotten-password")
);
const Otp = lazy(() => import("modules/authentication/otp"));
const SetPassword = lazy(() => import("modules/authentication/set-password"));
const SessionTimeout = lazy(
  () => import("modules/authentication/session-timeout")
);
const Confirmation = lazy(() => import("modules/authentication/confirmation"));

const Dashboard = lazy(() => import("modules/home/dashboard"));
const Tasks = lazy(() => import("modules/home/tasks"));
const Frameworks = lazy(() => import("modules/home/framworks"));
const DynamicFramework = lazy(
  () => import("modules/home/framworks/dynamic-framework")
);

const PolicyCenter = lazy(() => import("modules/home/policy-center"));

const CustomizedTemplates = lazy(
  () => import("modules/home/customized-templates")
);
const EvidenceHub = lazy(() => import("modules/home/evidence-hub"));
const Notifications = lazy(() => import("modules/home/notifications"));
const Audit = lazy(() => import("modules/home/audit"));
const AuditDetails = lazy(() => import("modules/home/audit/audit-details"));
const Evidence = lazy(() => import("modules/home/evidence"));
const Personnel = lazy(() => import("modules/home/personnel"));
const CompanyAgreement = lazy(() => import("modules/onboarding/agreements"));
const CompanyUrls = lazy(() => import("modules/onboarding/company-urls"));
const CompanyDetails = lazy(() => import("modules/onboarding/company-details"));
const CompanyInfo = lazy(() => import("modules/onboarding/company-info"));
const CompanyAddress = lazy(() => import("modules/onboarding/address"));
const RiskAssessMent = lazy(
  () => import("components/risk-assessment/RiskAssessmentModal/maincomponent")
);
const RiskRegister = lazy(() => import("modules/home/risk-register"));
const RiskTreatMent = lazy(
  () => import("components/risk-treatment/risk-tratment-contex/contex-wrap")
);
const AuditGeneralInfo = lazy(() => import("modules/home/audit/generalInfo"));
const AuditAgenda = lazy(() => import("modules/home/audit/auditAgenda"));
const AuditTeam = lazy(() => import("modules/home/audit/auditorTeam"));
const AuditTimetable = lazy(() => import("modules/home/audit/auditTimetable"));
const Auditlayout = lazy(() => import("modules/home/audit/audit-layout"));
const AuditDetailslayout = lazy(
  () => import("modules/home/audit/audit-details-layout")
);

const Auditinprogress = lazy(() => import("modules/home/audit/Inprogress"));
const Auditcompleted = lazy(() => import("modules/home/audit/completed"));
const Auditscheduled = lazy(() => import("modules/home/audit/scheduled"));

const Nonconformity = lazy(() => import("modules/home/audit/nonconformity"));
const Observation = lazy(() => import("modules/home/audit/observation"));
const Opportunity = lazy(() => import("modules/home/audit/opportunity"));
const ConformityLayout = lazy(
  () => import("modules/home/audit/conformity-layout")
);

const RiskReport = lazy(() => import("modules/home/risk-report"));
const Assets = lazy(() => import("modules/home/assets"));
const EmployeeTraning = lazy(() => import("modules/home/employee-training"));
const EmployeePersonnelTraining = lazy(
  () => import("modules/home/employee-training/personnel-training")
);
const EmployeePersonnelPerformance = lazy(
  () => import("modules/home/employee-training/personnel-performance")
);
const EmployeeBadges = lazy(
  () => import("modules/home/employee-training/badges")
);
const EmployeeDynamicTraining = lazy(
  () => import("modules/home/employee-training/dynamic-training")
);
const CompanySettings = lazy(() => import("modules/home/company-settings"));
const CompanySettingsInfo = lazy(
  () => import("modules/home/company-settings/company-infomation")
);
const CompanySettingsNotifications = lazy(
  () => import("modules/home/company-settings/company-notifications")
);
const CompanySettingsPermission = lazy(
  () => import("modules/home/company-settings/company-permission")
);
const CompanySettingsNewPermission = lazy(
  () =>
    import(
      "modules/home/company-settings/company-permission/company-new-permission"
    )
);

const CompanySettingsSecurity = lazy(
  () => import("modules/home/company-settings/company-sercurity")
);

const MainBody = ({ children }: any) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isDesktop ? (
    <main className="h-dvh w-full tracking-[0.2px]  font-dm_sans overflow-y-scroll">
      {children ? children : <Outlet />}
    </main>
  ) : (
    <div className="h-screen flex flex-col gap-4 justify-center items-center bg-[#06249C] px-3 relative">
      <img src={vegeelLogo} alt="" className="w-[124px] z-20" />
      <img
        src={overlay}
        alt=""
        className="absolute top-0 left-0 h-full w-full object-cover rounded-[20px]"
      />
      <p className="text-center text-sm text-white font-medium uppercase z-20">
        The web app is not accessible on mobile devices. Please verify it's
        functionality on a desktop
      </p>
    </div>
  );
};

export const ISuspense = ({ children }: any) => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

const MainRoutes = () => {
  const modules = createBrowserRouter([
    {
      element: <MainBody />,
      children: [
        {
          path: "/",
          element: <Authenticated />,
          children: [
            {
              index: true,
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <Dashboard />
                </ISuspense>
              ),
            },
            {
              path: "company-settings/permissions/new-permissions",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CompanySettingsNewPermission />
                </ISuspense>
              ),
            },
            {
              path: "/task",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <Tasks />
                </ISuspense>
              ),
            },
            {
              path: "/frameworks",
              children: [
                {
                  index: true,
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <Frameworks />
                    </ISuspense>
                  ),
                },
                {
                  path: ":frameworkName",
                  children: [
                    {
                      index: true,
                      element: (
                        <ISuspense>
                          <DynamicFramework />
                        </ISuspense>
                      ),
                      errorElement: <ErrorBoundary />,
                    },
                  ],
                },
              ],
            },
            {
              path: "/policy-center",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <PolicyCenter />
                </ISuspense>
              ),
            },
            {
              path: "/customized-templates",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CustomizedTemplates />
                </ISuspense>
              ),
            },
            {
              path: "/evidence-hub",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <EvidenceHub />
                </ISuspense>
              ),
            },
            {
              path: "/notifications",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <Notifications />
                </ISuspense>
              ),
            },
            {
              path: "/audit",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <Auditlayout />
                </ISuspense>
              ),
              children: [
                {
                  index: true,
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <Audit />
                    </ISuspense>
                  ),
                },
                {
                  path: "inprogress",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <Auditinprogress />
                    </ISuspense>
                  ),
                },
                {
                  path: "completed",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <Auditcompleted />
                    </ISuspense>
                  ),
                },
                {
                  path: "scheduled",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <Auditscheduled />
                    </ISuspense>
                  ),
                },
                {
                  path: ":id",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <AuditDetailslayout />
                    </ISuspense>
                  ),
                  children: [
                    {
                      index: true,
                      errorElement: <ErrorBoundary />,
                      element: (
                        <ISuspense>
                          <AuditDetails />
                        </ISuspense>
                      ),
                    },
                    {
                      path: "conformity",
                      children: [
                        {
                          index: true,
                          errorElement: <ErrorBoundary />,
                          element: (
                            <ISuspense>
                              <Nonconformity />
                            </ISuspense>
                          ),
                        },
                        {
                          path: "observation",
                          errorElement: <ErrorBoundary />,
                          element: (
                            <ISuspense>
                              <Observation />
                            </ISuspense>
                          ),
                        },
                        {
                          path: "opportunity",
                          errorElement: <ErrorBoundary />,
                          element: (
                            <ISuspense>
                              <Opportunity />
                            </ISuspense>
                          ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              path: "create-audit",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CreateAudit />
                </ISuspense>
              ),
              children: [
                {
                  path: "generalInfo",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <AuditGeneralInfo />
                    </ISuspense>
                  ),
                },
                {
                  path: "team",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <AuditTeam />
                    </ISuspense>
                  ),
                },
                {
                  path: "timetable",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <AuditTimetable />
                    </ISuspense>
                  ),
                },
                {
                  path: "agenda",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <AuditAgenda />
                    </ISuspense>
                  ),
                },
              ],
            },
            {
              path: "/audit/evidence",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <Evidence />
                </ISuspense>
              ),
            },
            {
              path: "/risk-assessment",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <ProtectedRoute
                    allowedRoles={["ADMIN", "SUPER_ADMIN", "PARTNER"]}
                    component={RiskAssessMent}
                  />
                  {/* <RiskAssessMent /> */}
                </ISuspense>
              ),
            },
            {
              path: "/risk-register",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <ProtectedRoute
                    allowedRoles={["ADMIN", "SUPER_ADMIN", "PARTNER"]}
                    component={RiskRegister}
                  />
                  {/* <RiskRegister /> */}
                </ISuspense>
              ),
            },
            {
              path: "/risk-treatment",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <ProtectedRoute
                    allowedRoles={["ADMIN", "SUPER_ADMIN", "PARTNER"]}
                    component={RiskTreatMent}
                  />
                  {/* <RiskTreatMent /> */}
                </ISuspense>
              ),
            },
            {
              path: "/risk-report",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <ProtectedRoute
                    allowedRoles={["ADMIN", "SUPER_ADMIN", "PARTNER"]}
                    component={RiskReport}
                  />
                  {/* <RiskReport /> */}
                </ISuspense>
              ),
            },

            {
              path: "/assets",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <Assets />
                </ISuspense>
              ),
            },
            {
              path: "/personnel",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <ProtectedRoute
                    allowedRoles={["SUPER_ADMIN", "ADMIN"]}
                    component={Personnel}
                  />
                </ISuspense>
              ),
            },
            {
              path: "/employee-training",
              errorElement: <ErrorBoundary />,
              element: (
                <FilteredDataProvider>
                  <ISuspense>
                    <EmployeeTraning />
                  </ISuspense>
                </FilteredDataProvider>
              ),
              children: [
                {
                  index: true,
                  errorElement: <ErrorBoundary />,
                  element: (
                    <FilteredDataProvider>
                      <ISuspense>
                        <EmployeePersonnelTraining />
                      </ISuspense>
                    </FilteredDataProvider>
                  ),
                },
                {
                  path: "badges",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <FilteredDataProvider>
                      <ISuspense>
                        <EmployeeBadges />
                      </ISuspense>
                    </FilteredDataProvider>
                  ),
                },
                {
                  path: "personnel-performance",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <FilteredDataProvider>
                      <ISuspense>
                        <EmployeePersonnelPerformance />
                      </ISuspense>
                    </FilteredDataProvider>
                  ),
                },
              ],
            },
            {
              path: "/employee-training/:traning",
              errorElement: <ErrorBoundary />,
              element: (
                <FilteredDataProvider>
                  <ISuspense>
                    <EmployeeDynamicTraining />
                  </ISuspense>
                </FilteredDataProvider>
              ),
            },
            {
              path: "/company-settings",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <SettingsProvider>
                    <ProtectedRoute
                      allowedRoles={["ADMIN", "SUPER_ADMIN", "PARTNER"]}
                      component={CompanySettings}
                    />
                  </SettingsProvider>
                </ISuspense>
              ),
              children: [
                {
                  index: true,
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <CompanySettingsInfo />
                    </ISuspense>
                  ),
                },
                {
                  path: "notifications",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <CompanySettingsNotifications />
                    </ISuspense>
                  ),
                },
                {
                  path: "permissions",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <CompanySettingsPermission />
                    </ISuspense>
                  ),
                },
                {
                  path: "/company-settings/permissions/new-permissions",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <CompanySettingsNewPermission />
                    </ISuspense>
                  ),
                },
                {
                  path: "security",
                  errorElement: <ErrorBoundary />,
                  element: (
                    <ISuspense>
                      <CompanySettingsSecurity />
                    </ISuspense>
                  ),
                },
              ],
            },
            // {
            //   path: "*",
            //   element: (
            //     <ISuspense>
            //       <NotFound />
            //     </ISuspense>
            //   ),
            // },
          ],
        },
        {
          path: "/:page/:name/editor",
          element: <Editor />,
          children: [
            {
              index: true,
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <EditorContainer />
                </ISuspense>
              ),
            },
          ],
        },
        {
          path: "/onboarding",
          errorElement: <ErrorBoundary />,
          element: (
            <ISuspense>
              <Onboarding />
            </ISuspense>
          ),
          children: [
            {
              index: true,
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CompanyInfo />
                </ISuspense>
              ),
            },
            {
              path: "details",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CompanyDetails />
                </ISuspense>
              ),
            },
            {
              path: "address",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CompanyAddress />
                </ISuspense>
              ),
            },
            {
              path: "urls",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CompanyUrls />
                </ISuspense>
              ),
            },
            {
              path: "agreements",
              errorElement: <ErrorBoundary />,
              element: (
                <ISuspense>
                  <CompanyAgreement />
                </ISuspense>
              ),
            },
          ],
        },
        {
          path: "/auth",
          element: <Unauthenticated />,
          children: [
            {
              index: true,
              element: (
                <AuthProvider>
                  <ISuspense>
                    <Signin />
                  </ISuspense>
                </AuthProvider>
              ),
            },
            {
              path: "forgotten-password",
              element: (
                <ISuspense>
                  <ForgottenPassword />
                </ISuspense>
              ),
            },
            {
              path: "confirmation",
              element: (
                <ISuspense>
                  <Confirmation />
                </ISuspense>
              ),
            },
            {
              path: "otp",
              element: (
                <ISuspense>
                  <Otp />
                </ISuspense>
              ),
            },
            {
              path: "set-password/:token",
              element: (
                <ISuspense>
                  <SetPassword />
                </ISuspense>
              ),
            },
            {
              path: "session-timeout",
              element: (
                <ISuspense>
                  <SessionTimeout />
                </ISuspense>
              ),
            },
            {
              path: " ",
              element: (
                <ISuspense>
                  <ImplemeterAuth />
                </ISuspense>
              ),
            },
          ],
        },

        {
          path: "*",
          element: (
            <ISuspense>
              <NotFound />
            </ISuspense>
          ),
        },
        // {
        //   path: "*",
        //   element: (
        //     <ISuspense>
        //       <AuthNotFound />
        //     </ISuspense>
        //   ),
        // },
      ],
    },
  ]);

  return <RouterProvider router={modules}  />;
};

export default MainRoutes;
