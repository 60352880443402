import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { SidebarCenterItem } from "types";
import { sidebarCenter as initialSidebarCenter } from "constants/home";
import { useAppContext, useFrameworkContext } from "context";
import { getTokenFromLocalStore } from "utils";
import { jwtDecode } from "jwt-decode";

export type UserRole =
  | "APPROVER"
  | "REVIEWER"
  | "INTERNAL_AUDITOR"
  | "SUPER_ADMIN"
  | "PARTNER"
  | "ADMIN";

const roleBasedSidebarMapping: Record<
  UserRole,
  (section: SidebarCenterItem) => SidebarCenterItem | null
> = {
  APPROVER: (section) => {
    if (section.parentTitle.title === "COMPLIANCE") return section;
    if (section.parentTitle.title === "Governance") {
      return {
        ...section,
        parentTitle: {
          ...section.parentTitle,
          paths: section.parentTitle.paths.filter(
            (path) => path.title === "Employee Training"
          ),
        },
      };
    }
    return null;
  },
  REVIEWER: (section) => {
    return roleBasedSidebarMapping.APPROVER(section);
  },
  INTERNAL_AUDITOR: (section) => {
    return roleBasedSidebarMapping.APPROVER(section);
  },
  SUPER_ADMIN: (section) => section,
  PARTNER: (section) => section,
  ADMIN: (section) => section,
};

interface SidebarContextType {
  sidebarData: SidebarCenterItem[];
  extraSidebarPaths: any[] | null;
  updateSidebarState: (
    parentTitle: string,
    pathTitle: string,
    shouldOpenSidebar: boolean
  ) => void;
  active: { active: string };
  setActive: React.Dispatch<React.SetStateAction<{ active: string }>>;
  pathName: string;
  setPathName: React.Dispatch<React.SetStateAction<string>>;
  userRole: UserRole | null;
  updatePaths: (path: string) => void;
  newPath: string | null;
  setNewPath: React.Dispatch<React.SetStateAction<string | null>>;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { activeFrameworks, frameworkPaths } = useFrameworkContext();
  const [active, setActive] = useState<{ active: string }>({ active: "" });
  const [pathName, setPathName] = useState<string>("");
  const [newPath, setNewPath] = useState<string | null>(null);
  const [sidebarData, setSidebarData] = useState<SidebarCenterItem[]>([]);
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [extraSidebarPaths, setExtraSidebarPaths] = useState<any[] | null>(
    null
  );

  useEffect(() => {
    const userRoleFromStorage = getTokenFromLocalStore<string>("userRole");
    if (userRoleFromStorage) {
      setUserRole(JSON.parse(userRoleFromStorage) as UserRole);
    }
  }, []);

  useEffect(() => {
    const userRole = getTokenFromLocalStore<string>("userRole");

    if (userRole) {
      const parsedRole: UserRole = JSON.parse(userRole);
      setUserRole(parsedRole);

      const filteredSidebarCenter = initialSidebarCenter
        .map((section) => {
          const filteredPaths = section.parentTitle.paths.filter((path) =>
            path.roles.includes(parsedRole)
          );

          if (filteredPaths.length > 0) {
            return {
              ...section,
              parentTitle: {
                ...section.parentTitle,
                paths: filteredPaths,
              },
            };
          }
          return null;
        })
        .filter(Boolean) as SidebarCenterItem[];

      setSidebarData(filteredSidebarCenter);
    }
  }, []);

  useEffect(() => {
    setExtraSidebarPaths(frameworkPaths);
  }, [frameworkPaths]);

  useEffect(() => {
    const businessFrameworks = activeFrameworks || [];

    if (businessFrameworks.length > 0) {
      const updatedSidebarData = sidebarData.map((section) => {
        if (section.parentTitle.title === "COMPLIANCE") {
          return {
            ...section,
            parentTitle: {
              ...section.parentTitle,
              paths: section.parentTitle.paths.map((path) => {
                switch (path.title) {
                  case "Frameworks":
                    return {
                      ...path,
                      sideBarPaths: frameworkPaths,
                    };
                  case "Policy Center":
                    return {
                      ...path,
                      link: `/policy-center`,
                      sideBarPaths: businessFrameworks.map((framework) => ({
                        label: framework.name,
                        path: framework.name,
                      })),
                    };
                  case "Evidence Hub":
                    return {
                      ...path,
                      link: `/evidence-hub`,
                      sideBarPaths: businessFrameworks.map((framework) => ({
                        label: framework.name,
                        path: framework.name,
                      })),
                    };
                  case "Customized Templates":
                    return {
                      ...path,
                      link: `/customized-templates`,
                      sideBarPaths: businessFrameworks.map((framework) => ({
                        label: framework.name,
                        path: framework.name,
                      })),
                    };
                  default:
                    return path;
                }
              }),
            },
          };
        }
        if (section.parentTitle.title === "AUDIT HUB") {
          return {
            ...section,
            parentTitle: {
              ...section.parentTitle,
              paths: section.parentTitle.paths.map((path) => {
                switch (path.title) {
                  case "Evidence":
                    return {
                      ...path,
                      link: `/audit/evidence`,
                      sideBarPaths: businessFrameworks.map((framework) => ({
                        label: framework.name,
                        path: framework.name,
                      })),
                    };
                  default:
                    return path;
                }
              }),
            },
          };
        }
        return section;
      });

      if (JSON.stringify(updatedSidebarData) !== JSON.stringify(sidebarData)) {
        setSidebarData(updatedSidebarData);
      }
    }
  }, [activeFrameworks, frameworkPaths, sidebarData]);

  const updateSidebarState = (
    parentTitle: string,
    pathTitle: string,
    shouldOpenSidebar: boolean
  ) => {
    const updatedSidebarData = sidebarData.map((section) => {
      if (section.parentTitle.title === parentTitle) {
        return {
          ...section,
          parentTitle: {
            ...section.parentTitle,
            paths: section.parentTitle.paths.map((path) => {
              if (path.title === pathTitle) {
                return {
                  ...path,
                  shouldOpenSidebar,
                };
              }
              return path;
            }),
          },
        };
      }
      return section;
    });

    setSidebarData(updatedSidebarData);
  };

  const updatePaths = (path: string) => {
    setNewPath(path);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarData,
        extraSidebarPaths,
        active,
        pathName,
        userRole,
        newPath,
        setActive,
        setPathName,
        updateSidebarState,
        updatePaths,
        setNewPath,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebarContext must be used within a SidebarProvider");
  }
  return context;
};
