import { IUserDataDetails } from "context";
import { saveKeyToLocalStore } from "./localstore";
import chroma from "chroma-js";
import { IRoles } from "types/roles";

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color =
    "#" +
    ((hash >> 24) & 0xff).toString(16).padStart(2, "0") +
    ((hash >> 16) & 0xff).toString(16).padStart(2, "0") +
    ((hash >> 8) & 0xff).toString(16).padStart(2, "0");

  return chroma(color).brighten(2).hex();
};

export const getCorrespondingColors = (str: string) => {
  const baseColor = stringToColor(str);
  const lightColor = chroma(baseColor).brighten(2.6).alpha(0.8).hex();

  return {
    pathColor: baseColor,
    trailColor: lightColor,
  };
};

export const slugify = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

export const deslugify = (slug: string) => {
  return slug.replace(/-/g, " ").toUpperCase(); // Adjust according to your slugify logic
};

export const storeUserRole = (data: IUserDataDetails) => {
  const attributes = data;

  if (attributes.roleName) {
    saveKeyToLocalStore("userRole", JSON.stringify(attributes.roleName));
  }
};

export const getFileExtension = (url: string) => {
  const parts = url.split(".");
  return parts[parts.length - 1].toLowerCase();
};

export function getInitials(fullName: string) {
  if (typeof fullName !== "string") {
    return "";
  }

  let names = fullName.split(/[_\s]+/);
  let firstInitial = names[0][0];
  let lastInitial = names.length > 1 ? names[names.length - 1][0] : "";
  return firstInitial + (lastInitial ? " " + lastInitial : "");
}

export function formatString(arr: string[]): string {
  const hasBulletPoints = arr.some((item) => item.trim().startsWith("•"));

  if (hasBulletPoints) {
    const formattedString = arr
      .filter((item) => item.trim().startsWith("•"))
      .map((item) => item.replace(/^•\s*|\.$/g, "").trim())
      .join(", ");

    return `${arr[0]} ${formattedString}`;
  } else {
    return arr[0];
  }
}

export function timeAgo(timestamp: string | number | Date): string {
  const now = new Date();
  const time = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - time.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return "Less than a minute ago";
  } else if (diffInSeconds < 3600) {
    // Less than an hour
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (diffInSeconds < 86400) {
    // Less than a day
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else {
    // More than a day ago
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }
}

export function replaceUnderscoresWithSpaces(str: any) {
  if (typeof str !== "string") {
    throw new Error("Input must be a string");
  }
  return str.replace(/_/g, " ");
}

export const cleanUserRole = (role: string): string => {
  const cleanedRole = role.replace(/^/, "");

  return cleanedRole.replace(/_/g, " ");
};

export function extractNumberFromString(input: string): number {
  const match = input.match(/\d+/);
  return match ? parseInt(match[0], 10) : 0;
}

export const findRolePublicId = (
  roleData: any[],
  personnelData: { roleName: any }
) => {
  const matchingRole = roleData.find(
    (role: { name: any }) => role.name === personnelData.roleName
  );
  return matchingRole ? matchingRole.publicId : null;
};
export function getPersonnelProfile(personnelInfoState: any[], name?: string) {
  if (name) {
    const foundPersonnel = personnelInfoState.find(
      (personnel: any) =>
        personnel?.firstName === name ||
        personnel?.lastName === name ||
        personnel?.publicId === name
    );
    if (foundPersonnel) {
      return foundPersonnel.profilePicture;
    }
  }
  return null;
}

export function getRoleId(roleName: string, roles: IRoles[] | null) {
  if (roles) {
    const foundRole = roles.find((role) => role.name === roleName);
    return foundRole ? foundRole.publicId : null;
  }
}
