import useFetch from "hooks/useFetch";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getAllPersonnelService } from "services";
import { getAllRoleService } from "services/roles";
import { toast } from "sonner";
import { IPersonnelData, IPersonnelResponse } from "types/personnel";
// import { IRolesApiResponse } from "types/app";6
import { IRoles, IRolesApiResponse } from "types/roles";
import { getTokenFromLocalStore, saveKeyToLocalStore } from "utils";

interface IAddressDetails {
  publicId: string;
  createdBy: string;
  dirty: null;
  approved: null;
  lastModifiedBy: null;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipOrPostalCode: string;
  country: string;
}

interface IContactDetails {
  publicId: string;
  companyId: string;
  companyName: string;
  createdDate: null;
  lastModifiedDate: null;
  createdBy: string;
  dirty: null;
  approved: null;
  lastModifiedBy: null;
  phone: string;
  email: string;
  website: string;
}

interface IBusinessUrls {
  publicId: string;
  termsOfUse: string;
  privacyPolicy: string;
  serviceAgreement: string;
  faq: string;
}

export interface IOrgDataResponse {
  data: IOrgDataDetails;
  description: string;
  isError: boolean;
  isSuccess: boolean;
  status: string;
}

export interface IUserDataResponse {
  timestamp: string;
  data: IUserData;
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
}

export interface IUserData {
  accessToken: string;
  refreshToken: string;
  userData: IUserDataDetails;
}
type NotificationSettingsType = {
  id: number;
  publicId: string;
  createdDate: string;
  lastModifiedDate: string;
  createdByRole: string;
  dirty: boolean;
  approved: boolean;
  createdBy: string;
  lastModifiedBy: string;
  companyId: string;
  companyName: string;
  pushDocumentToApp: boolean;
  pushDocumentToMail: boolean;
  pushTaskToApp: boolean;
  pushTaskToMail: boolean;
  pushCommentsToApp: boolean;
  pushCommentToMail: boolean;
  pushReminderToApp: boolean;
  pushReminderToMail: boolean;
  pushMentionsToApp: boolean;
  pushMentionsToMail: boolean;
};

export interface IUserDataDetails {
  publicId: string;
  companyId: string;
  companyName: string;
  notificationSettings?: NotificationSettingsType | null;
  createdDate: string;
  lastModifiedDate: string;
  createdBy: string | null;
  createdByRole: string | null;
  lastModifiedBy: string | null;
  email: string;
  firstName: string;
  lastName: string;
  department: string | null;
  position: string | null;
  username: string;
  roleName: string;
  is2faEnabled: boolean | null;
  training: ITrainingDetails;
  vulnerableByPhishing: string | null;
  profilePicture: string | null;
  sessionTimeout?: any | null;
  automaticLogoutTime?: any | null;
}

export interface ITrainingDetails {
  LEAD_AUDITOR: number;
  SECURITY_AWARENESS: number;
  LEAD_IMPLEMENTER: number;
}

export interface IOrgDataDetails {
  publicId: string;
  companyId: string;
  companyName: string;
  createdDate: null;
  lastModifiedDate: null;
  createdBy: string;
  approved: null;
  legalName: string;
  displayName: string;
  businessType: string;
  sectorOrIndustry: string;
  registrationNo: string;
  yearFounded: string;
  businessOverview: string;
  logo: string | File;
  businessAddressDetails: IAddressDetails;
  businessContactDetails: IContactDetails;
  businessUrls: IBusinessUrls;
  agreement: boolean;
  onboarded: true;
  businessFrameworks: IOrgInfoFramework[];
  businessModules: string[];
  subscriptionType: string;
  businessStatus: string;
  skippedOnBoarding: boolean;
  companyEmail: string;
}

export interface IOrgInfoFramework {
  publicId: string;
  createdDate: string;
  lastModifiedDate: string;
  createdBy: any | null;
  approved: boolean;
  lastModifiedBy: any | null;
  name: string;
  version: "v4.0";
  type: any | null;
  description: string;
  year: number;
  availability: boolean;
  logo: string;
}

interface AppContextType {
  organizationInfo: IOrgDataDetails | null;
  userInfoState: IUserDataDetails | null;
  personnelInfoState: IPersonnelData;
  setPersonnelInfo: any;
  rolesState: IRoles[] | null;
  page: number;
  size: number;
  totalPage: number;
  personnelLoading: boolean;
  rolesLoading: boolean;
  setOrganizationInfo: (orgData: IOrgDataDetails) => void;
  getOrganizationInfo: () => IOrgDataDetails | null;
  setUserInfo: (userInfo: IUserDataDetails) => void;
  getUserInfo: () => IUserDataDetails | null;
  refetchPersonnelData: () => Promise<void>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setDepartment: React.Dispatch<React.SetStateAction<string>>;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
}

export interface Personnel {
  publicId: string;
  companyId: string;
  companyName: string;
  createdDate: string;
  lastModifiedDate: string;
  createdBy: string | null;
  createdByRole: string | null;
  lastModifiedBy: string | null;
  email: string;
  firstName: string;
  lastName: string;
  department: string | null;
  position: string | null;
  username: string;
  roleName: string;
  is2faEnabled: boolean | null;
  training: {
    SECURITY_AWARENESS: number;
    LEAD_AUDITOR: number;
    LEAD_IMPLEMENTER: number;
  };
  vulnerableByPhishing: string | null;
  profilePicture: string | null;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [organizationInfo, setOrganizationInfoState] =
    useState<IOrgDataDetails | null>(null);
  const [userInfoState, setUserInfoState] = useState<IUserDataDetails | null>(
    null
  );
  const [rolesState, setRolesState] = useState<IRoles[] | null>(null);
  const [personnelInfoState, setPersonnelInfoState] = useState<any | null>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [name, setName] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [position, setPosition] = useState<string>("");

  const {
    data: personnelData,
    error: personnelError,
    loading: personnelLoading,
    fetchData: personnelFetchData,
  } = useFetch<IPersonnelResponse>();
  const {
    data: roles,
    error: rolesError,
    loading: rolesLoading,
    fetchData: rolesFetchData,
  } = useFetch<IRolesApiResponse>();

  const getPersonels = useCallback(() => {
    const params = {
      page,
      size,
      firstName: name,
      department,
      position,
    };
    personnelFetchData(() => getAllPersonnelService(params));
  }, [page, size]);

  useEffect(() => {
    getPersonels();
    rolesFetchData(() => getAllRoleService());
  }, [personnelFetchData, rolesFetchData, getPersonels]);

  useEffect(() => {
    if (personnelError || rolesError) {
      toast.error(rolesError || personnelError);
    }
  }, [personnelError, rolesError]);

  useEffect(() => {
    if (personnelData) {
      setPersonnelInfoState(personnelData.data);
    }

    if (roles) {
      setRolesState(roles.data.content);
    }
  }, [personnelData, roles]);

  const refetchPersonnelData = async () => {
    getPersonels();
  };

  const setPersonnelInfo = (personnelInfo: any) => {
    setPersonnelInfoState(personnelInfo);
  };

  const setUserInfo = (userInfo: IUserDataDetails) => {
    setUserInfoState(userInfo);
    saveKeyToLocalStore("userInfo", JSON.stringify(userInfo));
  };

  const setOrganizationInfo = (orgData: IOrgDataDetails) => {
    setOrganizationInfoState(orgData);
    saveKeyToLocalStore("orgDetails", JSON.stringify(orgData));
  };

  const getOrganizationInfo = (): IOrgDataDetails | null => {
    const orgData = getTokenFromLocalStore<string>("orgDetails");
    if (orgData) {
      const data: IOrgDataDetails = JSON.parse(orgData);
      return data;
    }
    return null;
  };

  const getUserInfo = (): IUserDataDetails | null => {
    const userInfo = getTokenFromLocalStore<string>("userInfo");
    if (userInfo) {
      const data: IUserDataDetails = JSON.parse(userInfo);
      return data;
    }
    return null;
  };

  return (
    <AppContext.Provider
      value={{
        personnelInfoState,
        rolesState,
        page,
        size,
        totalPage,
        userInfoState,
        organizationInfo,
        personnelLoading,
        rolesLoading,
        setPersonnelInfo,
        setPage,
        setSize,
        setOrganizationInfo,
        getOrganizationInfo,
        refetchPersonnelData,
        setUserInfo,
        getUserInfo,
        setDepartment,
        setName,
        setPosition,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
